import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { coins, coinInfo } from "facts";
import { countryCodeEmoji } from "country-code-emoji";

import { ALL_VENDORS } from "network/queries";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Home() {
  const navigate = useNavigate();

  const { error, loading, data } = useQuery(ALL_VENDORS);

  const [vendor, setVendor] = useState("all");

  useEffect(() => {
    if (vendor && vendor !== "all") {
      navigate("/vendor/" + vendor);
    }
  }, [vendor]);

  const BKGD =
    "rounded-md bg-gradient-to-br from-secondary to-black/10 via-black/10 ";

  return (
    <div className="flex flex-col items-center py-4 m-2 grow text-primary">
      <div className="flex-1"></div>

      <section
        className={
          BKGD +
          "w-full max-w-4xl mb-8 flex flex-col md:flex-row gap-2 py-2 md:py-4 px-[3%]"
        }
      >
        <div className="py-4 text-xl text-center md:text-left md:leading-10 md:text-3xl md:w-1/3">
          Find the best rate among multiple vendors for your currency.
        </div>
        <div
          className="grid gap-1 gap-x-4 grow"
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(6rem, 1fr))",
          }}
          role="group"
        >
          {coins.map(c => (
            <Link
              to={"currency/" + c}
              key={c}
              className="p-0.5 hover:rounded whitespace-nowrap tracking-wider text-center text-black active:bg-secondary text-primary  hover:shadow-lg hover:bg-secondary border-b border-black/10"
            >
              <span className="mr-2 text-2xl align-middle flag">
                {coinInfo[c] ? countryCodeEmoji(coinInfo[c].cc) : null}
              </span>
              {c}
            </Link>
          ))}
        </div>
      </section>

      <div className="flex flex-col items-stretch w-full max-w-4xl gap-4 mb-8 text-center md:flex-row">
        <section
          className={
            BKGD +
            "flex items-center justify-center gap-4 flex-col text-xl p-4 py-8 flex-1"
          }
        >
          <div>Learn more about your favourite vendor...</div>
          <FormControl variant="outlined" size="small">
            <Select
              native
              value={vendor}
              onChange={e => setVendor(e.target.value)}
              className="max-w-[15ch]"
            >
              <option value="all">Select...</option>
              {loading ? (
                <option disabled>Loading...</option>
              ) : error ? null : (
                data.vendors.map(v => (
                  <option key={v.userId} value={v.userId}>
                    {v.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        </section>

        <section
          className={BKGD + "overflow-hidden text-xl flex items-stretch flex-1"}
        >
          <div
            className="flex flex-col items-center justify-center flex-1 gap-4 p-4 py-8"
            style={{
              background: "url(../mtr.svg) bottom right/cover no-repeat",
            }}
          >
            <h2
              className="grow"
              style={{
                textShadow: "1px 1px 5px white, 1px 1px 8px white",
              }}
            >
              View a list vendors near your area...
            </h2>
            <Button variant="contained" component={Link} to="/locations">
              Visit Maps
            </Button>
          </div>
        </section>
      </div>

      <Link
        to="/blog/how-to-identify-authentic-rmb-notes"
        className="w-full max-w-4xl mb-8 rounded-md hover:underline focus:underline"
        style={{
          background: "url(/articles/rmb_notes.jpg) center/cover no-repeat",
        }}
      >
        <div
          className="py-16 text-xl md:text-3xl px-[3%] text-black text-center"
          style={{ textShadow: "1px 1px 6px white, 1px 1px 12px white" }}
        >
          Tips on avoiding counterfeit RMB notes
        </div>
      </Link>

      <Link
        to="/blog/how-to-identify-authentic-usd-notes"
        className="w-full max-w-4xl rounded-md hover:underline focus:underline"
        style={{
          background: "url(/articles/usd_notes.jpg) center/cover no-repeat",
        }}
      >
        <div
          className="py-16 text-xl md:text-3xl px-[3%] text-black text-center"
          style={{ textShadow: "1px 1px 6px white, 1px 1px 12px white" }}
        >
          Tips on avoiding counterfeit USD notes
        </div>
      </Link>

      {error ? (
        <div
          className="p-1 mt-4 text-xs bg-red-500 rounded text-paper error-icon"
          role="alert"
        >
          {error.message}
        </div>
      ) : null}

      <div className="flex-1"></div>
    </div>
  );
}
