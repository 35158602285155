import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center p-3 text-xl text-center grow">
      <TravelExploreIcon className="w-10 h-10 fill-primary" />

      <h1 className="my-4 text-xl text-primary">
        The link could not be found. Try again later.
      </h1>

      <Button component={Link} to="/" variant="outlined" size="small">
        Go home
      </Button>
    </div>
  );
}
