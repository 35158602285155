import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NhostClient, NhostReactProvider } from "@nhost/react";
import { NhostApolloProvider } from "@nhost/react-apollo";

import { ThemeProvider } from "@mui/material/styles";
import { muiTheme } from "./MuiTheme";

import ErrorBoundary from "components/ErrorBoundary";
import LazyLoading from "components/LazyLoading";

import MainLayout from "components/layouts/Main";

//routes
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Blog from "./pages/Blog";

//lazy loaded routes
const Login = lazySuspense(() => import("./pages/Login"));
const Register = lazySuspense(() => import("./pages/Register"));
const Edit = lazySuspense(() => import("./pages/Edit"));
const Currency = lazySuspense(() => import("./pages/Currency"));
const Vendor = lazySuspense(() => import("./pages/Vendor"));
const Profile = lazySuspense(() => import("./pages/Profile"));
const Bookings = lazySuspense(() => import("./pages/Bookings"));
const Chat = lazySuspense(() => import("./pages/Chat"));
const Market = lazySuspense(() => import("./pages/Market"));
const Locations = lazySuspense(() => import("./pages/Locations"));

const Admin = lazySuspense(() => import("./pages/Admin"));

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="market" element={<Market />} />
          <Route path="edit" element={<Edit />} />
          <Route path="currency/*" element={<Currency />} />
          <Route path="vendor/:id" element={<Vendor />} />
          <Route path="profile" element={<Profile />} />
          <Route path="bookings/*" element={<Bookings />} />
          <Route path="chat/*" element={<Chat />} />
          <Route path="locations" element={<Locations />} />
          <Route path="admin" element={<Admin />} />
          <Route path="blog/*" element={<Blog />} />
        </Route>
      </Routes>
    </Router>
  );
}

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
});

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <NhostReactProvider nhost={nhost}>
        <NhostApolloProvider nhost={nhost}>
          <AppRoutes />
        </NhostApolloProvider>
      </NhostReactProvider>
    </ThemeProvider>
  );
}

function lazySuspense(fn) {
  const Component = lazy(
    fn, //n => new Promise(ok => setTimeout(() => ok(fn()), 5000))
  );
  return function (props) {
    return (
      <ErrorBoundary>
        <Suspense fallback={<LazyLoading />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}

export default App;
