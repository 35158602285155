export const coins =
  "AUD,CAD,CHF,CNY,DKK,EUR,GBP,JPY,NOK,NZD,SEK,SGD,THB,USD,IDR,INR,KRW,MOP,MYR,PHP,RUB,TWD,BND,ZAR".split(
    ",",
  );

//https://en.wikipedia.org/wiki/ISO_4217
export const coinInfo: Record<string, { cc: string; n: string }> = {
  AUD: { cc: "AU", n: "Australian Dollar" },
  CAD: { cc: "CA", n: "Canadian Dollar" },
  CHF: { cc: "CH", n: "Swiss Franc" },
  CNY: { cc: "CN", n: "Renminbi" },
  DKK: { cc: "DK", n: "Danish Krone" },
  EUR: { cc: "EU", n: "Euro" },
  GBP: { cc: "GB", n: "Pound Sterling" },
  HKD: { cc: "HK", n: "Hong Kong Dollar" },
  JPY: { cc: "JP", n: "Japanese Yen" },
  NOK: { cc: "NO", n: "Norwegian Krone" },
  SEK: { cc: "SE", n: "Swedish Krona" },
  SGD: { cc: "SG", n: "Singapore Dollar" },
  THB: { cc: "TH", n: "Thai Baht" },
  USD: { cc: "US", n: "US Dollar" },
  IDR: { cc: "ID", n: "Indonesian Rupiah" },
  INR: { cc: "IN", n: "Indian Rupee" },
  KRW: { cc: "KR", n: "South Korean Won" },
  MOP: { cc: "MO", n: "Macanese Pataca" },
  NZD: { cc: "NZ", n: "New Zealand Dollar" },
  MYR: { cc: "MY", n: "Malaysian Ringgit" },
  PHP: { cc: "PH", n: "Philippine Peso" },
  RUB: { cc: "RU", n: "Russian Ruble" },
  TWD: { cc: "TW", n: "New Taiwan Dollar" },
  BND: { cc: "BN", n: "Brunei Dollar" },
  ZAR: { cc: "ZA", n: "South African Rand" },
};

type District = { id: string; en: string; zh: string; rgn?: string };
type Region = { id: string; en: string; zh: string; list: District[] };

export const regions: Region[] = [
  {
    id: "nt",
    en: "New Territories",
    zh: "新界",
    list: [
      { id: "islands", en: "Islands", zh: "離島區" },
      { id: "kwaitsing", en: "Kwai Tsing", zh: "葵青區" },
      { id: "north", en: "North", zh: "北區" },
      { id: "saikung", en: "Sai Kung", zh: "西貢區" },
      { id: "shatin", en: "Sha Tin", zh: "沙田區" },
      { id: "taipo", en: "Tai Po", zh: "大埔區" },
      { id: "tsuenwan", en: "Tsuen Wan", zh: "荃灣區" },
      { id: "tuenmun", en: "Tuen Mun", zh: "屯門區" },
      { id: "yuenlong", en: "Yuen Long", zh: "元朗區" },
    ],
  },
  {
    id: "kln",
    en: "Kowloon",
    zh: "九龍",
    list: [
      { id: "klncity", en: "Kowloon City", zh: "九龍城區" },
      { id: "kwuntong", en: "Kwun Tong", zh: "觀塘區" },
      { id: "shamshuipo", en: "Sham Shui Po", zh: "深水埗區" },
      { id: "wongtaisin", en: "Wong Tai Sin", zh: "黃大仙區" },
      { id: "yautsimmong", en: "Yau Tsim Mong", zh: "油尖旺區" },
    ],
  },
  {
    id: "hk",
    en: "Hong Kong Island",
    zh: "香港島",
    list: [
      { id: "central", en: "Central and Western", zh: "中西區" },
      { id: "eastern", en: "Eastern", zh: "東區" },
      { id: "southern", en: "Southern", zh: "南區" },
      { id: "wanchai", en: "Wan Chai", zh: "灣仔區" },
    ],
  },
];

export const districts: Record<string, District> = {};

regions.forEach(r =>
  r.list.forEach(d => {
    d.rgn = r.id;
    districts[d.id] = d;
  }),
);
