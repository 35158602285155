import { useState, useMemo } from "react";
import { Link, useNavigate, useHref } from "react-router-dom";
import {
  useAuthenticationStatus,
  useUserId,
  useSignOut,
  useUserDefaultRole,
} from "@nhost/react";
import { useQuery } from "@apollo/client";

import { BASIC_PROFILE } from "network/queries";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import { ReactComponent as Logo } from "assets/logo.svg";

import PublicIcon from "@mui/icons-material/Public";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MapIcon from "@mui/icons-material/Map";
import TableViewIcon from "@mui/icons-material/TableView";
import ChatIcon from "@mui/icons-material/Chat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import PasswordIcon from "@mui/icons-material/Password";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import NewspaperIcon from "@mui/icons-material/Newspaper";

export default function Header() {
  const { isAuthenticated: isAuthed, isLoading } = useAuthenticationStatus();
  const id = useUserId();
  const role = useUserDefaultRole();

  const { data, loading } = useQuery(BASIC_PROFILE, {
    variables: { id },
    fetchPolicy: "network-only",
    skip: !id,
  });

  //the user's bookings that have replies from vendors
  const bookingCount = data?.user?.bookings_aggregate?.aggregate?.count || 0;

  //the vendor's rate bookings
  const orderCount = useMemo(
    () =>
      data?.user?.rates.reduce((total, cur) => {
        return (cur.bookings_aggregate.aggregate.count || 0) + total;
      }, 0),
    [data?.user?.rates],
  );

  //unread chats
  const hasUnreadChats = useMemo(() => {
    let last = data?.chats?.[0];
    if (!last || last.toId === id) return false;
    let read = window.localStorage["read_chats"];
    if (!read || (read > 0 && Date.parse(last.createdAt) > read)) return true;
  }, [data?.chats, id]);

  const mainLinks = [
    ["/market", "Market Rates", PublicIcon],
    ["/currency", "Find Rates", QueryStatsIcon],
    isAuthed ? ["/edit", "Add Rates", TableViewIcon, true] : null,
    ["/locations", "Locations", MapIcon],
    isAuthed && role === "admin" ? ["/admin", "Admin", LocalPoliceIcon] : null,
    ["/blog", "Blog", NewspaperIcon, true],
  ].filter(i => !!i);

  const sideLinks = [
    isAuthed ? ["/chat", "Chat", ChatIcon, hasUnreadChats ? "⦿" : null] : null,
    isAuthed
      ? [
          "/bookings",
          "Bookings",
          ShoppingCartIcon,
          bookingCount > 0 || orderCount > 0
            ? "(" + Math.max(bookingCount, orderCount) + ")"
            : null,
        ]
      : null,
  ].filter(i => !!i);

  const [profileNode, setProfileNode] = useState(null);
  const hideProfileMenu = () => {
    setProfileNode(null);
  };

  const [hamburgerNode, setHamburgerNode] = useState(null);
  const hideHamburgerMenu = () => {
    setHamburgerNode(null);
  };

  const { signOut } = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    hideProfileMenu();
    hideHamburgerMenu();
    signOut();
    navigate("/");
  };

  const LINK_CLASS =
    "flex items-center py-1 lg:py-2 gap-1 hover:text-paper transition-colors duration-500";

  const OTHER_CLASS =
    LINK_CLASS + " border-transparent border-b-[3px] mt-[4px] mb-px";
  const ACTIVE_CLASS =
    LINK_CLASS + " border-secondary text-paper border-b-[3px] mt-[4px] mb-px";

  const href = useHref();

  return (
    <header className="text-secondary">
      <nav className="flex items-stretch gap-2 pl-2 text-xs text-center sm:gap-3 lg:gap-6 md:pl-4 grow bg-gradient-to-tr from-primary to-primaryd md:text-sm">
        <Link to="/" className={LINK_CLASS} title="ExRates">
          <Logo className="object-contain w-10 h-10" />
          {/*<span className="hidden mr-4 lg:block">ExRates</span>*/}
        </Link>

        {mainLinks.map(([url, label, Icon, hideMobile]) => (
          <Link
            key={url}
            to={url}
            className={
              (href === url ? ACTIVE_CLASS : OTHER_CLASS) +
              (hideMobile ? " hidden sm:flex" : "")
            }
          >
            <Icon className="w-5 h-5" />
            <span className="hidden md:block">{label}</span>
          </Link>
        ))}

        <div className="grow"></div>

        <div className="relative z-0 flex items-stretch justify-end gap-2 px-2 md:px-4 sm:gap-3 lg:gap-6">
          <div className="absolute inset-0 origin-top -skew-x-[30deg] right-1/3 -z-10 bg-gradient-to-r border-l border-primaryd from-primary to-transparent"></div>

          {sideLinks.map(([url, label, Icon, extra]) => (
            <Link
              key={url}
              to={url}
              className={url === href ? ACTIVE_CLASS : OTHER_CLASS}
            >
              <Icon className="w-5 h-5" />
              <span className="hidden lg:block">{label}</span>
              {extra || extra !== 0 ? (
                <span className="text-paper">{extra}</span>
              ) : null}
            </Link>
          ))}

          {isAuthed ? (
            <button
              className={"text-inherit hidden md:flex " + LINK_CLASS}
              onClick={e => setProfileNode(e.target)}
            >
              <AccountCircleIcon className="w-5 h-5" />
              <div className="max-w-[8ch] truncate hidden lg:block">
                {data?.user?.displayName || ""}
              </div>
            </button>
          ) : (
            <>
              <Link to="/login" className={LINK_CLASS + " hidden md:flex"}>
                <PersonIcon
                  className={
                    "w-5 h-5" + (isLoading || loading ? " animate-pulse" : "")
                  }
                />
                Login
              </Link>
              <Link to="/register" className={LINK_CLASS}>
                <PersonAddIcon className="w-5 h-5" />
                Register
              </Link>
            </>
          )}
          <IconButton
            size="small"
            className="block text-inherit md:hidden"
            onClick={e => setHamburgerNode(e.target)}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </nav>

      <Menu
        anchorEl={profileNode}
        open={!!profileNode}
        onClose={hideProfileMenu}
        disablePortal
      >
        <MenuItem component={Link} to="/profile" onClick={hideProfileMenu}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Profile
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={hamburgerNode}
        open={!!hamburgerNode}
        onClose={hideHamburgerMenu}
        disablePortal
      >
        {mainLinks.map(([url, label, Icon]) => (
          <MenuItem
            key={url}
            component={Link}
            to={url}
            onClick={hideHamburgerMenu}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}

        {sideLinks.length > 0 ? <Divider /> : null}

        {sideLinks.map(([url, label, Icon, extra]) => (
          <MenuItem
            key={url}
            component={Link}
            to={url}
            onClick={hideHamburgerMenu}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            {label}
            <span className="ml-1">{extra || extra !== 0 ? extra : null}</span>
          </MenuItem>
        ))}

        <Divider />

        {!isAuthed ? null : (
          <MenuItem component={Link} to="/profile" onClick={hideHamburgerMenu}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            Profile
          </MenuItem>
        )}
        {!isAuthed ? null : (
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Logout
          </MenuItem>
        )}
        {isAuthed ? null : (
          <MenuItem component={Link} to="/login" onClick={hideHamburgerMenu}>
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            Login
          </MenuItem>
        )}
        {isAuthed ? null : (
          <MenuItem component={Link} to="/register" onClick={hideHamburgerMenu}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            Register
          </MenuItem>
        )}
      </Menu>
    </header>
  );
}
