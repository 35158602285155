import { CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

let blogs: any[] = null;

export default function Blog() {
  const params = useParams();
  const id = params ? params["*"] : null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!blogs) {
      blogs = [];
      setLoading(true);
      fetch("/articles/sitemap.json")
        .then(
          res => res.json(),
          err => setError(err),
        )
        .then(res => {
          blogs = res.blogs;
          setLoading(false);
        });
    }
  }, []);

  const blog = useMemo(() => {
    if (id && blogs?.length > 0) {
      return blogs.find(b => b.id === id);
    }
  }, [id, loading]);

  if (error) {
    return (
      <div className="flex items-center text-center text-red-500 grow">
        {error.message}
      </div>
    );
  }

  if (loading || !blogs) {
    return (
      <div className="flex items-center justify-center grow">
        <CircularProgress size={20} />;
      </div>
    );
  }

  if (blog) {
    return (
      <iframe
        src={blog.url.replace("{id}", blog.id)}
        className="grow"
        title="blog"
      />
    );
  }

  return (
    <div className="w-full max-w-screen-md p-4 mx-auto grow">
      <h1 className="flex-1 my-1 text-xl font-bold text-center md:my-8 md:text-3xl text-primary">
        Blog
      </h1>

      {blogs.map(b => (
        <Link
          key={b.id}
          to={b.id}
          className="flex flex-col items-stretch m-4 mb-8 overflow-hidden border rounded-lg text-primary border-secondary"
        >
          <img src={b.banner} className="object-cover h-28" alt="banner" />
          <h2 className="p-2 pb-0 text-2xl text-center border-t border-secondary">
            {b.title.en}
          </h2>
          <div className="flex justify-between p-1.5 px-2 text-sm text-dimmed">
            <div>#finance</div>
            <div>{new Date(b.posted).toLocaleDateString()}</div>
          </div>
        </Link>
      ))}
    </div>
  );
}
