import { gql } from "@apollo/client";

export const BASIC_PROFILE = gql`
  query basic($id: uuid!) {
    user(id: $id) {
      id
      displayName
      vendor {
        userId
      }
      bookings_aggregate(
        where: {
          deletedAt: { _is_null: true }
          admin: { id: { _is_null: false } }
        }
      ) {
        aggregate {
          count
        }
      }
      rates(
        where: {
          bookings: {
            _and: [
              { deletedAt: { _is_null: true } }
              { _not: { admin: { id: { _is_null: false } } } }
            ]
          }
        }
      ) {
        bookings_aggregate(
          where: {
            _and: [
              { deletedAt: { _is_null: true } }
              { _not: { admin: { id: { _is_null: false } } } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
    chats(
      order_by: { createdAt: desc }
      limit: 1
      where: { _or: [{ toId: { _eq: $id } }, { fromId: { _eq: $id } }] }
    ) {
      createdAt
      toId
    }
  }
`;

export const RATES_BY_USER = gql`
  query search(
    $userId: uuid!
    $offset: Int = 0
    $limit: Int = 25
    $orderBy: [rates_order_by!] = { updatedAt: desc }
  ) {
    rates(
      where: { userId: { _eq: $userId } }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      cashBuy
      cashSell
      coin
      id
      ttBuy
      ttSell
      book
      updatedAt
      userId
    }
    agg: rates_aggregate(where: { userId: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
    vendors_by_pk(userId: $userId) {
      name
      nameZh
      verified
    }
  }
`;

export const RATES_BY_CURRENCY = gql`
  query ratesbyCoin(
    $coin: String!
    $offset: Int = 0
    $limit: Int = 50
    $orderBy: [vendors_order_by!] = [
      { district: asc }
      { rates_aggregate: { max: { updatedAt: desc } } }
    ]
  ) {
    vendors(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: { verified: { _eq: true }, rates: { coin: { _eq: $coin } } }
    ) {
      rates(
        limit: 1
        order_by: { updatedAt: desc }
        where: { coin: { _eq: $coin } }
      ) {
        cashBuy
        cashSell
        coin
        id
        ttBuy
        ttSell
        book
        updatedAt
      }
      avatar
      district
      logo: files(where: { file: { bucketId: { _eq: "logo" } } }, limit: 1) {
        fileId
      }
      name
      nameZh
      userId
    }
  }
`;

export const RATES_FOR_VENDOR = gql`
  query ratesForVendor($userId: uuid!) {
    vendors_by_pk(userId: $userId) {
      district
      logo: files(where: { file: { bucketId: { _eq: "logo" } } }, limit: 1) {
        fileId
      }
      photo: files(where: { file: { bucketId: { _eq: "photo" } } }, limit: 3) {
        fileId
        file {
          name
        }
      }
      metadata
      name
      nameZh
      rates(distinct_on: coin, order_by: { coin: asc, updatedAt: desc }) {
        cashBuy
        cashSell
        coin
        id
        ttBuy
        ttSell
        book
        updatedAt
      }
      userId
      verified
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile($id: uuid!) {
    user(id: $id) {
      displayName
      email
      id
      locale
      phoneNumber
      metadata
      vendor {
        id: userId
        avatar
        district
        metadata
        name
        nameZh
        logo: files(where: { file: { bucketId: { _eq: "logo" } } }, limit: 1) {
          fileId
          file {
            name
          }
        }
        mso: files(where: { file: { bucketId: { _eq: "mso" } } }, limit: 1) {
          fileId
          file {
            name
          }
        }
        photo: files(
          where: { file: { bucketId: { _eq: "photo" } } }
          limit: 5
        ) {
          fileId
          file {
            name
          }
        }
        verified
      }
    }
  }
`;

export const ALL_VENDORS = gql`
  query allVendors {
    vendors(where: { verified: { _eq: true } }, order_by: { district: asc }) {
      district
      logo: files(where: { file: { bucketId: { _eq: "logo" } } }, limit: 1) {
        fileId
      }
      metadata
      name
      nameZh
      userId
    }
  }
`;

export const BOOKINGS_BY_USER = gql`
  query myBookings($id: uuid!, $limit: Int = 300) {
    bookings(
      where: { userId: { _eq: $id }, deletedAt: { _is_null: true } }
      limit: $limit
      order_by: { createdAt: desc }
    ) {
      admin {
        code
        createdAt
        resolvedAt
        id
      }
      amount
      createdAt
      id
      isWant
      rate {
        cashBuy
        cashSell
        coin
        ttBuy
        ttSell
        vendor {
          avatar
          logo: files(
            where: { file: { bucketId: { _eq: "logo" } } }
            limit: 1
          ) {
            fileId
          }
          name
          nameZh
          district
          metadata
        }
        userId
      }
    }
  }
`;

export const BOOKINGS_BY_VENDOR = gql`
  query myOrders($id: uuid!) {
    user(id: $id) {
      rates(
        order_by: [{ coin: asc }, { updatedAt: desc }]
        where: {
          bookings: {
            _and: [
              { deletedAt: { _is_null: true } }
              { _not: { admin: { resolvedAt: { _is_null: false } } } }
            ]
          }
        }
      ) {
        bookings(
          where: {
            _and: [
              { deletedAt: { _is_null: true } }
              { _not: { admin: { resolvedAt: { _is_null: false } } } }
            ]
          }
        ) {
          admin {
            code
            resolvedAt
            createdAt
            id
          }
          amount
          createdAt
          displayName
          id
          isWant
          user {
            bookings_aggregate(where: { rate: { userId: { _eq: $id } } }) {
              aggregate {
                count
              }
            }
            id
          }
        }
        coin
        cashBuy
        cashSell
        id
        ttBuy
        ttSell
        updatedAt
      }
    }
  }
`;

export const GET_PUSHKEYS = gql`
  query pushKeys($id: uuid!) {
    user_pushkeys_by_pk(id: $id) {
      id
      keys
    }
  }
`;

export const CHAT_THREADS = gql`
  query chatThreads($id: uuid!) {
    user(id: $id) {
      recentChatsDistinctUsers(args: { limit_num: 40 }) {
        id
        createdAt
        fromId
        toId
        fromDisplayName
        toDisplayName
      }
    }
  }
`;

export const CHATS = gql`
  query chats(
    $offset: Int = 0
    $limit: Int = 15
    $fromId: uuid!
    $toId: uuid!
  ) {
    chats(
      order_by: { createdAt: desc }
      offset: $offset
      limit: $limit
      where: {
        _or: [
          { toId: { _eq: $toId }, fromId: { _eq: $fromId } }
          { toId: { _eq: $fromId }, fromId: { _eq: $toId } }
        ]
      }
    ) {
      id
      createdAt
      fromId
      toId
      fromDisplayName
      toDisplayName
      text
    }
    fromAsVendor: vendors_by_pk(userId: $fromId) {
      name
      nameZh
      userId
      verified
    }
    toAsVendor: vendors_by_pk(userId: $toId) {
      name
      nameZh
      userId
      verified
    }
  }
`;

export const MUTUAL_BOOKINGS = gql`
  query mutualBookings($userId: uuid!, $vendorId: uuid!) {
    bookings(
      where: {
        deletedAt: { _is_null: true }
        _or: [
          { userId: { _eq: $userId }, rate: { userId: { _eq: $vendorId } } }
          { userId: { _eq: $vendorId }, rate: { userId: { _eq: $userId } } }
        ]
      }
      order_by: { createdAt: desc }
      limit: 10
    ) {
      id
      amount
      createdAt
      rate {
        cashBuy
        cashSell
        coin
        id
        ttBuy
        ttSell
        updatedAt
        userId
      }
      isWant
      admin {
        code
        resolvedAt
        createdAt
        id
      }
    }
  }
`;
